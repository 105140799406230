import http from './fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';

export default {
    pay(param, callbak) {
        return http.post(`${baseUrl}/pay/payHandler`, param)
    },
    getDeviceInfo(param, callbak) {
        return http.get(`${baseUrl}/pay/getDeviceInfo`, param)
    },
    getOrderStatus(param, callbak) {
        return http.get(`${baseUrl}/pay/getOrderStatus`, param)
    },
}