/*
 * @Date: 2020-05-09 10:50:28
 * @LastEditors: chenamin
 * @LastEditTime: 2020-05-18 10:51:01
 * @FilePath: \car\src\page\search\index.js
 * @desc: 
 */
import React, { Component } from 'react'
import { createForm } from 'rc-form';
import { List, Button, Toast, Modal} from 'antd-mobile';
import { getQueryString } from '../../utils/index'
import './index.less'
import Api from '../../api/apis'
import iconvLite  from 'iconv-lite'
let _json = [];
let _carJson = [];
let _mountingsJson = []
// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
const QRCode = require('qrcode.react')

let moneyKeyboardWrapProps;

if (isIPhone) {
	moneyKeyboardWrapProps = {
		onTouchStart: e => e.preventDefault(),
	};
}

function onNextFrame(cb) {
    if (window.requestAnimationFrame) {
      return window.requestAnimationFrame(cb);
    }
    return window.setTimeout(cb, 1);
}
class Success extends Component {
	constructor(props) {
		super(props)
		this.state = {
            isSearch: false,
            // 车型
            carType: '',
            // 配件
            mountings: '',
            isFocus: false,
            // 数据
            carData: [],
            // 查询出来的结果
            resultData: [],
            // 搜索弹窗 visible
            searchVisible: false,
            // 搜索类型 carType/mountings
            searchType: '',
            // 搜索数据
            searchValue: '',
            // 搜索结果
            searchResult: []
        }
	}
	componentDidMount() {
        

        async function getBuffer (key) {
            try {
                let result = await window.client.get(key);
                
                if(key === 'carMountingTypePriceVOList'){
                    _json = JSON.parse(iconvLite.decode(result.content,'utf8'));
                }else if(key === 'allFirstSecondCarTypeList'){
                    _carJson = JSON.parse(iconvLite.decode(result.content,'utf8'));
                }else if(key === 'mountingsTypeSet'){
                    _mountingsJson = JSON.parse(iconvLite.decode(result.content,'utf8'));
                }
                console.log(_json,_carJson,_mountingsJson,'_mountingsJson')
            } catch (e) {
            }
          }
          
        getBuffer('carMountingTypePriceVOList');
        getBuffer('allFirstSecondCarTypeList');
        getBuffer('mountingsTypeSet');
        
       
	}
	
    /**
     * @description: 点击搜索
     * @param {type} 
     * @return: 
     */
    onSearch = (e) => {
        e.preventDefault();
        this.handleList();
        this.setState({
            isSearch: true,
            isFocus: false,
        }, ()=> {
            this.inputRef1.blur();
            this.inputRef2.blur();
        });

    }
    /**
     * @description: 数据处理
     * @param {type} 
     * @return: 
     */
    handleList = () => {
        const {carType, mountings} = this.state;
        console.log(carType,mountings,'param')
        let _arr = _json.filter((val) => {
            let _bool = true;
            if(carType && val.carType.indexOf(carType) === -1 && val.parentCarType.indexOf(carType) === -1){
                _bool = false
            }else if(mountings && val.mountings.indexOf(mountings) ===  -1){
                _bool = false
            }
            
            return _bool
        })

        this.setState({
            resultData: _arr
        })

    }
    /**
     * @description: 搜索框点击清楚
     * @param {type} 
     * @return: 
     */
    onClear = (e) => {
        
        this.setState({ 
            searchValue: '', 
            searchResult: [],
            isSearch: this.state.searchValue ? true : false, 
        }, () => {
            this.handleList();
        });
    }

     /**
     * @description: 车型获得焦点
     * @param {type} 
     * @return: 
     */
    onFocus = () => {
        this.setState({ 
            isFocus: this.state.searchValue ? true : false,
        });
    }
    /**
     * @description: 焦点消失
     * @param {type} 
     * @return: 
     */
    onBlur = () => {
        // 延迟触发 onblur
        onNextFrame(() => {
            if(!this.blurFromOnClear){
                if (document.activeElement !== this.inputRef) {
                    this.setState({
                        isFocus: false,
                    });
                }
            }
        })
    }

    /**
     * @description: 搜索弹窗出现
     * @param {type} 
     * @return: 
     */    
    showSearch = (val) => {
        this.setState({
            searchVisible: true,
            searchType: val,
            isFocus: true
        })
    }
    onClose = () => {
        this.setState({
            searchVisible: false
        })
    }
    /**
     * @description: 点击弹窗取消
     * @param {type} 
     * @return: 
     */
    onSearchCancel = () => {
        this.setState({
            searchVisible: false,
            searchValue: '',
            searchResult: []
        })
    }
    /**
     * @description: 搜索变化
     * @param {type} 
     * @return: 
     */    
    onChangeModal = (e) => {
        const {searchType } = this.state;
        let _value = e.target.value
        
        this.setState({ 
            searchValue: _value,
            isFocus: _value ? true : false
        }, () => {
            // 匹配结果
            let _arr = []
            if(_value){
               
                if(searchType === 'carType'){
                    
                    for(let i in _carJson){
                        // 车型匹配
                        if(i.indexOf(_value) > -1){
                            _arr = [..._arr,..._carJson[i]]
                        }else{
                            let _carfilter = _carJson[i].filter(v=> {
                                return v.indexOf(_value) > -1
                            })
                            _arr = [..._arr,..._carfilter]
                        }

                        
                    }
                }else if(searchType === 'mountings'){
                    let _mountingFilter = _mountingsJson.filter(v=> {
                        return v.indexOf(_value) > -1
                    })
                    _arr = [..._arr,..._mountingFilter]
                }
            }
                this.setState({
                    searchResult : _arr
                })
           
            
           
        });
    }
    /**
     * @description: 弹窗搜索点击确定
     * @param {type} 
     * @return: 
     */
    onSearchModal = () => {
        const {searchType,searchValue} = this.state
        this.setState({
            [searchType] : searchValue,
            searchVisible: false,
            isFocus: false
        }, ()=> {
            this.setState({
                searchValue: '',
                searchResult: []
            })
        })
    }
    /**
     * @description: 点击弹窗搜索结果里的某一项
     * @param {type} 
     * @return: 
     */
    onSearchItem = (v) => {
        const {searchType } = this.state;
        this.setState({
            [searchType] : v,
            searchVisible : false,
            searchValue: '',
            searchResult: []
        })
    }
	render() {
        const { isSearch, isFocus, resultData, searchType, searchResult } = this.state
        
        let _result = (
            <section className='result-wrapper'>
                <div className="result-remark">查询结果：</div>
                {
                    resultData && resultData.length > 0 ? resultData.map((val, index) => {
                        return (
                            <div style={{paddingBottom: 20}} key={index}>
                                <div className='detail title'>{val.parentCarType}</div>
                                <div className='detail between'>
                                    <div>{val.carType}</div>
                                </div>
                                <div className='detail between'>
                                    <div>维修项</div>
                                    <div>{val.mountings}</div>
                                </div>
                                {   
                                    val.carPriceTypeDTOList.map((v,i) => {
                                        return <div className='detail between' key={i}>
                                            <div>{v.priceType}</div>
                                            <div> {v.price && v.price !== '/' ? '¥' + v.price : '/'}</div>
                                        </div>
                                    })
                                }
                                
                            </div>
                        )
                    }) : <div className='no-data-wrapper'  style={{paddingBottom: 20}}>
                        <img src={require('../../static/imgs/no-data.png')}></img>
                    </div>
                }
            </section>
           
        )
       
		return (
			<div className='search-wrapper'>
                <div className='img-wrapper'>
                    <img src={require('../../static/imgs/bg.png')}  alt="" />
                </div>
                <div className='input-wrapper'>
                    <i className="am-search-synthetic-ph-icon"></i>
                    <form  action="javasctip:;" onSubmit={this.onSearch.bind(this)}>
                        <input 
                            className='input-main' 
                            placeholder='请输入车型名称' 
                            type="search"
                            onBlur={this.onBlur.bind(this)}
                            value={this.state.carType}
                            autoComplete="off"
                            ref={el => (this.inputRef1 = el)}
                            onClick={this.showSearch.bind(this,'carType')}
                            readOnly
                        ></input>
                        <span className="input-search-btn" onClick={this.onSearch.bind(this)}>搜索</span>
                    </form>
                </div>
                <div className='input-wrapper' style={{marginTop: 20}}>
                    <i className="am-search-synthetic-ph-icon"></i>
                    <form  action="#" onSubmit={this.onSearch.bind(this)}>
                        <input 
                            className='input-main' 
                            placeholder='请输入配件名称' 
                            type="search"
                            onFocus={this.onFocus.bind(this)}
                            onBlur={this.onBlur.bind(this)}
                            value={this.state.mountings}
                            autoComplete="off"
                            ref={el => (this.inputRef2 = el)}
                            onClick={this.showSearch.bind(this,'mountings')}
                            readOnly
                        ></input>
                        <span className="input-search-btn" onClick={this.onSearch.bind(this)}>搜索</span>
                       
                    </form>
                   
                </div>
               
				{
			        isSearch ?  _result:  <div className="remark-wrapper">
                    <div>
                        注：
                    </div>
                    <div>
                    请各位驾驶员在车辆修理完毕交付使用后，认真审核结算清单中所设计的零配件材料价格及工时费用。相关费用可通过输入配件名称查询。如发生价格与查询结果不相符的，应通知修理方予以更正。若不按规定执行，造成违规的由驾驶员本人负责。
                    </div>
                
                </div>
                }

                {/* 搜索弹窗 */}
                <Modal
                    visible={this.state.searchVisible}
                    onClose={this.onClose.bind(this,'modal2')}
                    animationType='fade'
                >
                    <div className='search-modal-wrapper'>
                        {/* 搜索框 */}
                        <div className='search-input-wrapper'>
                            <div className='input-wrapper'>
                                <i className="am-search-synthetic-ph-icon"></i>
                                <form  action="javasctip:;" onSubmit={this.onSearchModal.bind(this)}>
                                    <input 
                                        className='input-main' 
                                        placeholder={`请输入${searchType === 'mountings' ? '配件名称' :'车型名称'}`} 
                                        onFocus={this.onFocus.bind(this)}
                                        onBlur={this.onBlur.bind(this)}
                                        onChange={this.onChangeModal.bind(this)}
                                        value={this.state.searchValue}
                                        autoComplete="off"
                                        ref={el => (this.inputRef1 = el)}
                                        autoFocus
                                    ></input>

                                </form>
                                <span
                                    onClick={this.onClear.bind(this)}  
                                    className={`am-search-clear ${isFocus ? 'am-search-clear-show' : ''}`}
                                ></span>
                            </div>

                            <span className="cancel-text" onClick={this.onSearchCancel.bind(this)}>取消</span>
                        </div>
                        {/* 结果 */}
                        <div className="search-result-wrapper">
                            {
                                searchResult.map(v => {
                                    return (
                                        <div className='search-result-item' onClick={this.onSearchItem.bind(this,v)}>{v}</div>
                                    )
                                })
                            }
                        </div>
                        

                    </div>
                </Modal>
				
			</div>
		)
	}
}

export default Success

