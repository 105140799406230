/*
 * @Date: 2020-05-09 10:41:58
 * @LastEditors: chenamin
 * @LastEditTime: 2020-05-13 11:30:06
 * @FilePath: \car\src\router\index.js
 * @desc: 
 */
import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import history from '../api/history';
import Search from '../page/search'
// let OSS = require('ali-oss');
let OSS = window.OSS.Wrapper
let client = new OSS({
	region: 'oss-cn-hangzhou',
	accessKeyId: 'LTAI4G356RPNzpFyHVkSijx7',
	accessKeySecret: 'qz1IYlHzjlpyHhy70ZiFhlCguh56by',
	secure: true,
	bucket: 'car-tool' //自定义的上传后地址，加在oss前
});
window.client = client
const RouterPages = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/" component={Search} />
				{/* <Route exact path="/success" component={Success} /> */}
			</Switch>
		</Router>
	)
}
export default RouterPages
